import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //align-items: center;
  z-index: 100;
  padding-top: 20px;
`;

export const CloseButton = styled.button`
  background-color: #fff;
  border: none;
  color: #4d5055;
  padding: 10px 50px;
  text-align: center;
  text-decoration: none;
  display: flex;
  font-size: 16px;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Icon = styled(Link)`
  margin-left: 32px;
  margin-top: 32px;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  align-items: center;


  @media screen and (max-width: 480px) {
    margin-left: 16px;
    margin-top: 8px;
  }
`;