import React, { useState } from 'react';

import ModalVideo from 'react-modal-video';
import './Hero.scss'

import {
  HeroBg,
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
  ImageBg,
  CalendarButton,
  ButtonsWrapper,
  VideoButton,
  InfoRow,
  Column1,
  Column2,
  ImgWrap,
  Img,
  InfoWrapper
} from './HeroElements'

const HeroSection = () => {

  const [hoverCalendar, setHoverCalendar] = useState(false);
  const [hoverVideo, setHoverVideo] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const onHoverCalendar = () => {
    setHoverCalendar(!hoverCalendar)
  };

  const onHoverVideo = () => {
    setHoverVideo(!hoverVideo)
  };



  return (
    <HeroContainer id='home'>
      <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId='NrFRnuBlo6M' onClose={() => setIsOpen(false)} />
      <HeroBg>
        <ImageBg
          src={require('../../images/bg-2.svg')}
          alt={'bg-2'}
        />
      </HeroBg>
      <InfoWrapper>
        <InfoRow>
          <Column1>
            <HeroContent>
              <HeroH1>Generamos Hábitos de Vida Saludable</HeroH1>
              <HeroP>De una manera innovadora, kipabits te ayuda a mejorar los hábitos de vida saludable en la etapa escolar de manera sostenible en el tiempo.</HeroP>
              <ButtonsWrapper>
                <HeroBtnWrapper>
                  <CalendarButton
                    to='/calendar'
                    onMouseEnter={onHoverCalendar}
                    onMouseLeave={onHoverCalendar}
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact={true}
                    offset={-80}
                  >
                    Agenda una reunión {hoverCalendar ? <ArrowForward /> : <ArrowRight />}
                  </CalendarButton>
                </HeroBtnWrapper>
                <HeroBtnWrapper>
                  <VideoButton
                    onClick={() => setIsOpen(true)}
                    onMouseEnter={onHoverVideo}
                    onMouseLeave={onHoverVideo}
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact={true}
                    offset={-80}
                  >
                    Ver Video {hoverVideo ? <ArrowForward /> : <ArrowRight />}
                  </VideoButton>
                </HeroBtnWrapper>
              </ButtonsWrapper>
            </HeroContent>
          </Column1>
          <Column2>
            <ImgWrap>
              <Img src={require('../../images/app-2.png')} alt={'app-2'}/>
            </ImgWrap>
          </Column2>
        </InfoRow>
      </InfoWrapper>
      
      
    </HeroContainer>
  )
}

export default HeroSection
