import React, { useState } from 'react'

import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour, homeObjFive, homeObjSix, homeObjSeven } from '../components/InfoSection/Data';
import Navbar from '../components/Navbar'
import Services from '../components/Services';
import Sidebar from '../components/Sidebar'
import Support from '../components/Support';
import Testimonials from '../components/Testimonios';

const Home = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Navbar toggle={toggle}/>
      <HeroSection/>
      <InfoSection {...homeObjOne}/>
      <InfoSection {...homeObjTwo}/>
      <Services />
      <InfoSection {...homeObjThree}/>
      <InfoSection {...homeObjFour}/>
      <InfoSection {...homeObjFive}/>
      <InfoSection {...homeObjSix}/>
      <InfoSection {...homeObjSeven}/>
      {/* <Testimonials /> */}
      {/* <Support /> */}
      <Footer />
      
    </>
  )
}

export default Home
