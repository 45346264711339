import React from 'react'

import Calendar from '../components/Calendar';

const CalendarPage = () => {
  return (
    <>
      <Calendar />
    </>
  )
}

export default CalendarPage
