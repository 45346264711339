import React from 'react';
import { InlineWidget } from 'react-calendly';

import {
  Background,
  Icon,
} from './CalendarElements'

const Calendar = () => {

  return (
    <> 
      <Background>
        <Icon to='/'>kipabits</Icon>
        <InlineWidget
          url='https://calendly.com/kipabits/30min'
          styles={{
            height: '100%',
            width: '100%',
            marginTop: '10px'
          }}
          pageSettings={{
            backgroundColor: 'ffffff',
            hideEventTypeDetails: false,
            hideLandingPageDetails: true,
            primaryColor: 'ff8000',
            textColor: '4d5055',
          }}
        />
      </Background>
    </>
  )
}

export default Calendar
