import React from 'react';

import Icon1 from '../../images/svg-3.svg'; 
import Icon2 from '../../images/svg-4.svg'; 
import Icon3 from '../../images/svg-5.svg'; 
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesElements';

const Services = () => {
  return (
    <ServicesContainer id={'services'}>
      <ServicesH1>Principales Funciones</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1}/>
          <ServicesH2>Llevalo a donde quieras</ServicesH2>
          <ServicesP>Desarrolla tu plan educativo en cualquier lugar mediante tu smartphone.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2}/>
          <ServicesH2>Bitacora personalizada</ServicesH2>
          <ServicesP>Obten feedback y contenido personalizado para tu desarrollo personal.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3}/>
          <ServicesH2>Base de datos experiencial</ServicesH2>
          <ServicesP>Tu colegio y tus padres podran ser parte de la mejora de tus indices.</ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  )
}

export default Services
