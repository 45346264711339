export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Herramienta de Apoyo Pedagógico',
  headline: 'Registra, analiza y aprende',
  description: 'Kipabits, como una manera intuitiva en su uso y registro, permite a los alumnos, sus familias y profesores aplicar mejores prácticas de hábitos de vida saludable a través de la convivencia escolar, con el fin de poder monitorear, aprender y beneficiarse de sus resultados.',
  buttonLabel: 'Conoce Más',
  imgStart: false,
  img: require('../../images/svg-1.svg'),
  alt: 'Car',
  dark: true,
  primary: true,
  darkText: false,
  showButton: true,
  goTo: 'discover'
};

export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Un compañero integral',
  headline: 'Mejora tu calidad de vida junto a tu comunidad escolar y familia',
  description: 'Kipabits se integra y se adapta a tu forma de vida, con el apoyo de tu familia, te acompaña en los procesos educativos de tu colegio.',
  buttonLabel: 'Beneficios',
  imgStart: false,
  img: require('../../images/svg-2.svg'),
  alt: 'laksndlk',
  dark: false,
  primary: false,
  darkText: true,
  showButton: true,
  goTo: 'features'
};

export const homeObjThree = {
  id: 'features',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Colegios',
  headline: 'Potencia y promociona la vida saludable de los alumnos',
  description: 'Kipabits es una herramienta de apoyo tecnológico que entrega un reporte estadístico sobre la calidad, estado nutricional y calidad de vida de los alumnos, promocionando hábitos y generando una mayor consciencia de vida saludable.',
  buttonLabel: 'Siguiente',
  imgStart: false,
  img: require('../../images/svg-6.svg'),
  alt: 'Papeers',
  dark: false,
  primary: false,
  darkText: true,
  showTitle: true,
  shortPage: true,
  showButton: true,
  goTo: 'profesores'
};

export const homeObjFour = {
  id: 'profesores',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Profesores',
  headline: 'Complemento innovador a las clases tradicionales',
  description: 'Kipabits es una herramienta de apoyo a la gestión pedagógica, en la que podrás centralizar la estadística generada por los alumnos, obteniendo una trazabilidad de los datos que sirva como una forma de evaluación cuantitativa a cada una de las actividades desarrolladas.',
  buttonLabel: 'Siguiente',
  imgStart: true,
  img: require('../../images/svg-7.svg'),
  alt: 'Papeers',
  dark: false,
  primary: false,
  darkText: true,
  shortPage: true,
  showButton: true,
  goTo: 'alumnos'
};

export const homeObjFive = {
  id: 'alumnos',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Alumnos',
  headline: 'Mejora tu calidad de vida y aumenta tu desarrollo académico',
  description: 'Kipabits es una aplicación didáctica que promueve diariamente el desarrollo y hábito de vida saludable mediante actividades tanto teóricas como prácticas, en las áreas de nutrición, salud y actividad física. Con esto, el alumno tendrá la herramienta necesaria para lograr una adecuada motivación, con el fin de lograr una autosuperación según sus metas personales.',
  buttonLabel: 'Siguiente',
  imgStart: false,
  img: require('../../images/svg-8.svg'),
  alt: 'Papeers',
  dark: false,
  primary: false,
  darkText: true,
  shortPage: true,
  showButton: true,
  goTo: 'apoderados'
};

export const homeObjSix = {
  id: 'apoderados',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Apoderados',
  headline: 'Conocimiento del estado de sus hijos',
  description: 'Kipabits es una aplicación móvil que tiene como objetivo contribuir a controlar y reducir la obesidad escolar y mejorar la convivencia escolar. Los padres podrán acompañar a sus hijos en el proceso de aprendizaje como apoyo en las diferentes actividades tanto teóricas como prácticas, obteniendo un registro actualizado del estado y progreso de sus hijos. Además, promueve el fortalecimiento de lazos padres e hijos y la consciencia de vida saludable.',
  buttonLabel: 'Siguiente',
  imgStart: true,
  img: require('../../images/svg-9.svg'),
  alt: 'Papeers',
  dark: false,
  primary: false,
  darkText: true,
  shortPage: true,
  showButton: true,
  goTo: 'gobierno'
};

export const homeObjSeven = {
  id: 'gobierno',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Gubernamental',
  headline: 'Datos y tendencias online',
  description: 'Primera herramienta en Chile que permite conocer online el estado integral de vida saludable de los niños, permitiendo generar mejoras en las politicas publicas, programas educativos y reorientación de los recursos.',
  buttonLabel: 'Registrate Ahora',
  imgStart: false,
  img: require('../../images/svg-10.svg'),
  alt: 'Papeers',
  dark: false,
  primary: false,
  darkText: true,
  shortPage: true,
  showButton: true,
  goTo: 'home'
};