import React from 'react'
import { Container, FormWrap, Icon, FormContent, Form, FormInput, FormButton, Text, FormH1, FormLabel, HeroH1, HeroP} from './SigninElements'

const SignIn = () => {
  return (
    <>
      <Container>
        <FormWrap>
          <Icon to='/'>kipabits</Icon>
          <FormContent>
          <HeroH1>Próximamente</HeroH1>
          <HeroP>soporte@kipabits.com</HeroP>
            {/* <Form action='#'>
              <FormH1>Sign in to your account</FormH1>
              <FormLabel htmlFor='for'>Email</FormLabel>
              <FormInput type='email' required/>
              <FormLabel htmlFor='for'>Password</FormLabel>
              <FormInput type='password' required/>
              <FormButton type='submit'>Continue</FormButton>
              <Text>Forgot Password</Text>
            </Form> */}
          </FormContent>
        </FormWrap>
      </Container>
    </>
  )
}

export default SignIn
